import "../../../../blocksuite/affine/fragments/outline/src/card/outline-card.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../../../blocksuite/affine/fragments/outline/src/card/outline-card.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VVwW7bMAy95yuEAAWagwrHTbpURb9huw5DD7RFO+oUyZDkJN6wfx8sO47sqNkCbKemlPz4SD4+PchKveePCfk5I6TSVjihFSMGJTixx5cZIVzYSkLDSCHx2AZAilJR4XBnGclROTRt+L22ThQNzbVyqFx4lOkjteKHUCUjmTYcDc308WX2a/bQ5//GwQG1DlxtX+fcQFkKVc7fel6iRaK4R+UsI0orz0xXkAvXMJI8rCNg2jjIJL7OnanxBAWcexppdSRJFXJYXunBQXC3ZWSZJHddPb4GA1zUlpFV5fuS18ZqwwjHAmrp2lBt0VCLEnN3on3Ox7Z6j8ZnzSD/XhpdK87IHsw9pVAUQiHdp1RC0/ZruEH9Z5/3aCQ0i4+b2KVFPn8jowr/R65gYP8s12i+z0HudDzJhGy6/k+GtBOKblGUW8dI2l8ZtHyS0AdaLqFiJ9g/ijcNBnnSQGkg+wtJjiqaLNr0472wIhNSuOZ1jrxEidYGEBESgxBvg3rsQLRsMaZTc3h0lAvbluGHFF/OIeMqVtrtHjLgrT2eZ+Exwq8LrVw7KpzQ7uI7TzdWVmZAcepPFgPOoZfOOknamGwvnuS0XI2c48lzGg472bSVMrIM7MLpyp8SDnaLfMKhu3Mm4QwoW2izY91PCQ6/3q+Tu3ANP427e1L12cQgs1rWzkdNx6+XtWdDn7p/+mXqKG7GsUw7p3dD+NrG9EYYzGGZBqFrLU0nZtzLOV7nZEE2cY3FXTownJgWfNpgDNFyh8zP8czTV8b/XV3p3wAIHtB3q4CdkE1UyqBsf7z44JW5vr+VETswI0OHiH/0qx3cyiZvZCivCxXeZP668/0voFAuItMLzXgLXB8mmF2Qpotrlh1MZnMh1Zss47KTUz3HhL/qhH/TeM+979+QrT68RdfhN6clm+HMCQAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var cardContent = 'lpnjc3a lpnjc33';
export var cardHeader = 'lpnjc32';
export var cardPreview = 'lpnjc31';
export var currentModeLabel = 'lpnjc39';
export var displayModeButton = 'lpnjc38';
export var displayModeButtonGroup = 'lpnjc37';
export var divider = 'lpnjc36';
export var headerIcon = 'lpnjc34 lpnjc33';
export var headerNumber = 'lpnjc35 lpnjc33';
export var modeChangePanel = 'lpnjc3b';
export var outlineCard = 'lpnjc30';