import "../../../../blocksuite/affine/fragments/outline/src/card/outline-preview.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../../../blocksuite/affine/fragments/outline/src/card/outline-preview.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62UwZLaMAyG7zyFL52Bg5lAgW7NTF+hz+DESqJdx/bYTgjb4d07dkJIgG2znR4t6ZOtX5LXtd5X7WtCfi0IybXyNOcVyjMjDbdLSnmeowI68qyOC0JOKHzJyCZJvoRjqlvq8B1VwUiqrQBLU90Gj+FCRPPBtOTFRNupRA/UGZ4BI0qfLDfBLNAZyc+M5BJi3GvtPOZnmmnlQXlGnOfWBw+XWCiKHirHSAbKgw3mghvWXXJZrPvCWKkbsLG8rLZOW0aMxiuR8uytsLpW4q7gZkslP4c6hggaM/1swEoeVLgs1jzz2AD5QdZjGTMtwzX3+Ty0nkJlSu7QrSZvVNovWcnd0hmuVquYZZBDaQWj6M3UexXrA0keNLy5+h5ut11XSsCi9LfzjJ7u+sjPiOgg00pw281Rn9hygbUbEn4gIGZaUWOx6uhBke2fVI+QQMdTCWPoa4Q+GMXQ6FzqEyMlCgEq2GL7bg6QEo1DF1yhB4xsjtcVcvgOzxbIVbFoGQz3ck/PwzN3t8U89RG7JBl1gUrIAzYd+/0jdniGJSPm8A/Mt5nMbvK6l5nUZqrF97nYYYLxmdg2mWDpXGxaW/bf5mpIKW4v6SYLVQkW/WiBUu29rhhJ1nvTEqcliun8CWwwBMYtWT38wqkF/tYd4kRX3BaoqO1nclIh/PWTe7Ju+SegXhMBmbbco1asWxlfWl0X5XFx+Q1E2+MRtgYAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var icon = 'uo5mxj1';
export var iconDisabled = 'uo5mxj2';
export var linkedDocPreviewUnavailable = 'uo5mxje';
export var linkedDocText = 'uo5mxjd';
export var linkedDocTextUnavailable = 'uo5mxjf';
export var outlineBlockPreview = 'uo5mxj0';
export var subtypeStyles = {title:'uo5mxj5',h1:'uo5mxj6',h2:'uo5mxj7',h3:'uo5mxj8',h4:'uo5mxj9',h5:'uo5mxja',h6:'uo5mxjb'};
export var text = 'uo5mxj3';
export var textGeneral = 'uo5mxj4';
export var textSpan = 'uo5mxjc';