import "../../../../blocksuite/affine/blocks/table/src/table-cell.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../../../blocksuite/affine/blocks/table/src/table-cell.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA+1VwY7TMBC99yt8QdoVctV0adl6T/AjaGJPW4PXE+xJt13EvyM7MUnK0gUtSBw4JZ5xnt+8eePMP2zs5/V6sxBfZkI0FC1b8koEdMD2gHczIcDZnZeW8T4qodEzhhSuKRgMSlTNUURy1gxBqclRUOIA4UpK2G6tR3lYSobaoez2XE+3O2giKlHeUtJGSiQSHWiZUojxyDLzUSIyBE7BAwa2GlxJMDV3s6+zeV9adVYa1JFcy/mIPdrdnpVYZHBq+jeH2xJ8sIb3SlSLxau0NDY2Dk5KbB0eL4jzsY1styepyTN6HlIDr5vMS8p+ufxRLqvJy4iavIFwyoLpNsSkbEO2HPUorTd4VGI54ru8bY7jAqt1t65Bf9oFar253KM9gsHwfthtcAut43HXAhjbRiX6o2o6yrgHQw9noHXLnArJuQxADWjLp6J7AF+a02fEYr6MAiGitF5Sy39ae7WnA4bcge9kqsmOOeg0AM82qdPLemM1MAXZd+Zd/npabfU7HRgQMxFgNNdjgm8u2PqSkxcTW/zM1ukpjQ2oO3RNrr33LxN99TcNXwxeKisD8N/wnfbPGn71rxt+nZm9WBMhdtAosUw9HMDfZvDeSzdTK91cdlJR6imnrNJ4DafcXpjZl8l0PhW/arG+eRIP6Dkq4cnjmPEmM873iaymsmhw+ipdIOJ1UvN6NIyrqbiQQcok44MMGO1jrjp0WLLX+JxO998fkOqM1F1q8qyFepzrufZ8nqJayjjjajJKTcx0X3gNWRzJ0R3/De5Jh3RBCQAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var cellContainerStyle = '_9iq6690';
export var columnIndicatorStyle = '_9iq6699 _9iq6698';
export var columnLeftIndicatorStyle = '_9iq669b _9iq6699 _9iq6698';
export var columnOptionsCellStyle = '_9iq6691';
export var columnOptionsStyle = '_9iq6693';
export var columnRightIndicatorStyle = '_9iq669a _9iq6699 _9iq6698';
export var indicatorStyle = '_9iq6698';
export var rowBottomIndicatorStyle = '_9iq669d _9iq669c _9iq6698';
export var rowIndicatorStyle = '_9iq669c _9iq6698';
export var rowOptionsCellStyle = '_9iq6694';
export var rowOptionsStyle = '_9iq6695';
export var rowTopIndicatorStyle = '_9iq669e _9iq669c _9iq6698';
export var threePointerIconDotStyle = '_9iq6697';
export var threePointerIconStyle = '_9iq6696';